.schedule-header {
  padding: 9px 30px 9px 20px;
  border-bottom: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4;
  gap: 10px;
  justify-content: space-between;
}

.schedule-header-blue {
  background: #01bbf8;
}

.schedule-header-gray {
  background: #d9d9d9;
}

.schedule-header-title {
  color: #000;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.schedule-item {
  background-color: white;
  color: gray;
  padding: 9px 35px 9px 20px;
  gap: 10px;
  border-bottom: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4;
  justify-content: space-between;
  align-items: center;
}

.schedule-item-title {
  color: black;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
}

.schedule-item-subtitle {
  color: #000;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.schedule-like {
  cursor: pointer;
}

.info-link {
  color: #f15bb4;
  font-family: "Roboto";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.mobile-partners {
  margin-bottom: 20px;
}

.mobile-partners img {
  max-width: 70px;
}

.info-header {
  color: #01bbf8;
  font-family: "Roboto";

  font-size: 23px;
}

.info-text {
  font-weight: 400;
  color: white;
  line-height: 1.1;
}

.yellow-ribbon {
  margin: 10px;
  width: 95%;
  height: auto;
}

.ribbon-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-left: 20px;
}
