.ticket-card {
  display: flex;
  flex: 1;
  padding: 26px 26px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  height: 370px;
  min-width: 300px;
  max-width: 300px;
  position: relative;
}

.pink {
  background-color: #ff5bbc;
  color: #111113;
}

.pink:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 80px solid #ff5bbc;
  border-left: 80px solid #03011d;
  width: 0;
}

.yellow {
  background-color: #faff00;
  color: #111113;
}

.yellow:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 80px solid #faff00;
  border-left: 80px solid #03011d;
  width: 0;
}

.blue {
  background-color: #10c4ff;
  color: #111113;
}
.blue:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 80px solid #10c4ff;
  border-left: 80px solid #03011d;
  width: 0;
}

.ticket-title {
  font-size: 34px;
  text-align: center;
  font-weight: 700;
}

.ticket-list {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: "Roboto";
}

.ticket-prices {
  margin-top: auto;
  margin-bottom: 5px;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.ticket-price-subtitle {
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tickets-buy-button {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  background: #fff;
  font-family: "Roboto";
  font-weight: 700;
  color: black;
  font-size: 16px;
  text-decoration: none;
  transition: transform 300ms ease-out;
}

.tickets-buy-button:hover {
  transform: scale(1.1);
}

.tickets-buy-button:active {
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.ticket-subtitle {
  align-self: flex-end;
  text-align: end;
  width: 78%;
  font-size: 18px;
}

@media screen and (max-width: 750px) {
  .ticket-card {
    height: 370px;
    min-width: 260px;
    max-width: 260px;
  }
}
