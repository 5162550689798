.guests-section {
  background: url(../../../public/background.jpg);
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-direction: column;
  align-items: center;
}

.clouds-down {
  margin-top: -270px;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}

.guests {
  margin-top: 115px;
}

.socials-text {
  color: #ffffff;
  margin: 20px 0px 50px 0px;
}

.socials-text-mobile {
  display: none;
}

@media screen and (max-width: 760px) {
  .guests {
    margin-top: 50px;
  }

  .socials-text {
    display: none;
  }

  .socials-text-mobile {
    display: flex;
    color: #ffffff;
    margin: 100px 0px 50px 0px;
  }
}
