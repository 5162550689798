.application-card {
  padding: 16px 20px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  width: 600px;
  cursor: pointer;

  background: #e6e8f4;
}

.application-text {
  color: black !important;
}

.application-card p {
  margin: 5px 0px;
}

.application-text {
  color: black !important;
}

.application-0 {
  background: #7edfff;
}

.application-2 {
  background: #e6e8f4;
}

.application-1 {
  background: #fee540;
}

.application-4 {
  background: #01f4d6;
}

.application-3 {
  background: #ff92d2;
}

@media screen and (max-width: 750px) {
  .application-card {
    width: unset;
  }

  .application-text {
    font-size: unset !important;
  }
}
