.footer-section {
  background-color: #01bbf8;
  justify-content: space-between;
  padding: 29px 330px;
  margin-top: auto;
}

.footer-text {
  color: #fff;
  text-align: start;
  font-size: 20px;
  font-weight: 400;
  font-family: "Roboto";
  line-height: 24px; /* 120% */
}

.footer-age-text {
  color: #fff;
  text-align: center;
  font-size: 35px;
}

@media screen and (max-width: 1450px) {
  .footer-section {
    padding: 29px;
  }
}

@media screen and (max-width: 760px) {
  .footer-section {
    padding: 20px 15px;
    gap: 20px;
  }
}
