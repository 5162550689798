.login-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  color: #000;
  font-family: "Roboto";
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  background: none;
}

.mobile-menu-section {
  height: 100%;
  width: 100%;
  align-items: center;
}

.mobile-menu-login {
  margin-top: auto;
  margin-bottom: 40px;
}

.userpic-small {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.link-text-small {
  font-family: "Roboto";
  text-decoration: none;
  font-size: 22px;
  color: black;
}

.menu-login-section {
  width: 100%;
}

@media screen and (max-width: 1450px) {
  .menu-section {
    width: 100%;
  }

  .link {
    color: #000;
    font-size: 20px;
  }

  .menu-item {
    padding: 10px 0px;
    width: 100%;
    justify-content: center;
  }

  .menu-item:hover,
  .menu-item-active {
    background-color: #faff00;
    color: #000;
  }

  .sub-section {
    display: none;
  }
}
