form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 392px;
  align-items: center;
  padding-bottom: 30px;
}

.form-label {
  color: #000;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

.form-input {
  width: 390px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.7);
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 6px 20px;
}

form button,
.button {
  margin-top: 10px;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  cursor: pointer;
  flex-wrap: wrap;
  background: #f15bb4;
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover styles */
form button:hover {
  background-color: #d64e9e; /* Lighter pink on hover */
}

/* Active (clicked) styles */
form button:active {
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.error-message {
  color: #ff92d2;
}

.registration-successful {
  font-family: "Roboto";
}

.small-text {
  margin-top: 15px;
  font-family: "Roboto";
  font-size: 12px;
  text-align: center;
}

.small-text a {
  color: #f15bb4;
}

@media screen and (max-width: 1450px) {
  form button,
  .button {
    padding: 12px 24px;
    font-size: 14px;
  }
}

@media screen and (max-width: 750px) {
  .form-input {
    width: 200px;
  }
}
