.photocosplay-page {
  gap: 50px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1450px) {
  .photocosplay-page {
    gap: 30px;
  }
}

@media screen and (max-width: 750px) {
  .photocosplay-page {
    gap: 20px;
  }
}
