@font-face {
  src: url("./fonts/FormaDJRCyrillicDeck-Medium-Testing.woff2") format("woff2"),
    url("./fonts/FormaDJRCyrillicDeck-Medium-Testing.woff") format("woff");
  font-family: "Roboto";
  font-style: normal;
}

@font-face {
  src: url("./fonts/FormaDJRCyrillicDeck-Bold-Testing.woff2") format("woff2"),
    url("./fonts/FormaDJRCyrillicDeck-Bold-Testing.woff") format("woff");
  font-family: "Roboto";
  font-style: normal;
}

@font-face {
  src: url("./fonts/FormaDJRCyrillicDeck-Regular-Testing.woff2") format("woff2"),
    url("./fonts/FormaDJRCyrillicDeck-Regular-Testing.woff") format("woff");
  font-family: "Roboto";
  font-style: normal;
}

.container {
  display: flex;
}

.container-v {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.color-section {
  background: url(../public/back_top.png);
}

.background {
  background: url(../public/background_no_black.png);
  position: absolute;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.screen-section {
  /* Set a fixed height for each section */
  margin-top: 64px;
  height: calc(100vh - 64px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.content-section {
  height: fit-content;
}

.content {
  max-width: 1180px;
  padding-top: 70px;
  margin-bottom: 100px;
}

.text {
  font-style: normal;
  line-height: normal;
  font-family: "Roboto";
}

.header-text {
  color: white;
  font-size: 69px;
  line-height: 75px;
  font-family: "Roboto";
  font-weight: 700;
}

.button {
  border: none;
}

.longread {
  padding: 10vh 10vw 0vh 10vw;
  flex: 1;
}

.longread-content {
  width: 80%;
}

.longread-content h1,
.header {
  color: #01bbf8;

  margin-bottom: 10px;
  /* BIG header */
  font-family: "Roboto";
  font-size: 69px;
  font-style: normal;
  line-height: 75px; /* 108.696% */
}

.longread-content h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  /* Header 2 */
  font-family: "Roboto";
  font-size: 35px;
  font-style: normal;
  line-height: normal;
}

.longread-content h4 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  /* Header 2 */
  font-family: "Roboto";
  font-size: 25px;
  font-style: normal;
  line-height: normal;
}

.longread-content h3 {
  padding: 15px;
  border: 2px dashed #01bbf8;
  color: #fff;
  width: fit-content;
  margin-top: 10px;
  margin-bottom: 10px;

  /* Text Body */
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.longread-content h3 p {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #f15bb4;

  /* Header 2 */
  font-family: "Roboto";
  font-size: 35px;
  font-style: normal;
  line-height: normal;
}

.longread-content p,
.longread-content ol,
.longread-content li,
.regular-text {
  color: #fff;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0px;
}

.longread-content li ul li {
  margin: 0px 0px;
}

.longread-content strong {
  font-family: "Roboto";
}

.longread-content a {
  color: #faff00;
  font-size: 20px;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: 5px;
  font-family: "Roboto";
}

.apply-button-longread {
  margin-top: 20px !important;
  padding: 32px 64px;
  text-align: center;
  border: none;
  gap: 10px;
  background: #f15bb4;
  color: #fff !important;
  font-size: 32px !important;
  margin-bottom: 40px;
  margin-top: 40px;
  cursor: pointer;
  width: 300px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Apply styles to the loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99; /* Ensure it's on top of everything */
}

/* Create a simple spinning ring */
.spinner {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #01bbf8; /* Blue border on top */
  border-radius: 50%; /* Circular shape */
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Animation for spinning */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.badge-text {
  text-wrap: nowrap;
}

@media screen and (max-width: 1450px) {
  .longread {
    padding: 20vh 8vw;
  }

  .longread-content {
    width: 100%;
  }

  .longread {
    max-width: 90vw;
    margin-top: 0px;
  }

  .screen-section {
    height: fit-content;
  }

  .header-text {
    text-align: center;
  }
}

@media screen and (max-width: 760px) {
  .longread {
    padding: 20vh 5vw 0vh 5vw;
    margin-top: 0px;
  }

  .longread-content {
    width: 100%;
    margin-top: -100px;
  }

  .longread-content h1,
  .header {
    font-size: 35px;
    line-height: normal;
  }

  .longread-content h2 {
    font-size: 30px;
  }

  .longread-content h4 {
    font-size: 22px;
  }

  .header-text {
    font-size: 35px;
    line-height: normal;
  }

  .screen-section {
    height: unset;
  }

  .content {
    padding: 70px 10px 0px 10px;
  }

  .apply-button-longread {
    padding: 16px 32px;
    width: 200px;
    font-size: 20px !important;
  }
}
