.photocosplay-thumbnail {
  width: 300px;
  height: 300px;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 300ms ease-out;
  object-fit: cover;
}

.photocosplay-thumbnail:hover {
  transform: scale(1.1);
  color: white;
}

.photocosplay-text {
  color: black !important;
}

.photocosplay-modal {
  width: 80vw;
  height: 80vh;
  background-color: white;
  padding: 47px 55px;
  border-radius: 25px;
}

.carousel-root {
  height: 70%;
}

.carousel-slider {
  height: 100%;
}

.slider-wrapper {
  height: 100%;
}

.slider {
  height: 100%;
}

.control-arrow {
  padding: 35px !important;
}

.photocosplay-rating div {
  overflow: unset !important;
}

@media screen and (max-width: 1450px) {
  .photocosplay-thumbnail {
    width: 200px;
    height: 200px;
    border-radius: 15px;
  }

  .photocosplay-modal {
    width: 80vw;
    height: 80vh;
    background-color: white;
    padding: 37px 45px;
    border-radius: 20px;
  }

  .photocosplay-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 750px) {
  .photocosplay-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }

  .photocosplay-modal {
    width: 70vw;
    height: 80vh;
    background-color: white;
    padding: 27px 35px;
    border-radius: 15px;
  }

  .photocosplay-text {
    font-size: 15px;
  }
}
