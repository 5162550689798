.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Ensure it appears above other content */
}

/* Modal */
.modal {
  display: flex;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 60%;
  height: 70%;
  position: relative;
  top: 3vh;
  padding: 50px 70px;
}

/* Modal Close Button */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

.modal-left {
  flex: 1;
  align-items: flex-start;
}

.popup-label {
  color: #01bbf8;
  font-size: 6vh;
  font-weight: bold;
}

.popup-text {
  color: #000;
  font-size: 2.5vh;
  font-family: "Roboto";
  font-weight: 500;
  overflow: hidden;
  word-wrap: normal;
  overflow-y: auto;
  margin: 15px 0px;
}

.popup-text a {
  color: #f15bb4;
  text-decoration: none;
  margin-top: auto;
  font-family: "Roboto";
}

.popup-button {
  display: inline-flex;
  padding: 3vh 6vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-wrap: wrap;
  background: #f15bb4;
  color: #fff;
  font-size: 4vh;
  cursor: pointer;
  margin-top: auto;
}

.socials {
  gap: 46px;
  margin-top: auto;
}

.social-link {
  transition: transform 300ms ease-out;
  max-width: 100px;
}
.social-link img {
  width: 100px;
}

.social-link:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 1450px) {
  .popup-button {
    display: inline-flex;
    padding: 2vh 4vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
    background: #f15bb4;
    color: #fff;
    font-size: 2vh;
    cursor: pointer;

    width: 90%;
  }

  .popup-label {
    color: #01bbf8;
    font-size: 25px;
    margin-bottom: 0px;
  }
}
