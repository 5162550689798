.show-timer {
  color: #000;
  font-size: 20px;
}

.timer-number div {
  font-weight: bold;
  font-size: 45px;
  margin-bottom: -5px;
}

.timer-number p {
  margin: 0px;
}

.timer-dot {
  margin: 35px 20px 0px 20px;
  color: #fff;
  font-size: 10px;
}

.timer-dot-small {
  margin: 30px 9px 0px 9px;
  color: #fff;
  font-size: 10px;
}

@media screen and (max-width: 1450px) {
  .timer-number div {
    font-size: 27px;
  }

  .timer-number p {
    font-size: 14px;
  }

  .timer-dot {
    font-size: 6px;
    margin: 20px 6px 0px 6px;
  }

  .timer-dot-small {
    font-size: 6px;
    margin: 20px 6px 0px 6px;
  }

  .text-small {
    font-size: 14px;
  }
}

@media screen and (max-width: 760px) {
  .timer-dot {
    color: #01bbf8;
  }

  .show-timer {
    text-align: center;
  }

  .timer-dot-small {
    font-size: 6px;
    margin: 20px 4px 0px 4px;
  }

  .text-small {
    font-size: 11px;
  }
}

@media screen and (max-width: 380px) {
  .timer-dot-small {
    font-size: 4px;
    margin: 13px 4px 0px 4px;
  }

  .text-small {
    font-size: 9px;
  }

  .text-small .timer-number div {
    font-size: 20px;
  }

  .text-small .timer-number p {
    font-size: 10px;
  }
}
