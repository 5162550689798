.typewriter-text {
  font-family: "Courier New", Courier, monospace;
  color: #fff;
  white-space: pre-wrap;
}

.quest-header {
  font-family: "Roboto";
  font-size: 20px;
  color: white;
  font-weight: 700;
  text-align: left;
}

.quest-page {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.quest-body {
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 30px;
  min-height: 60vh;
}

.quest-intruction-text {
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.quest-button {
  padding: 16px 32px;
  border: none;
  font-weight: 700;
  background: #01bbf8;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover styles */
.quest-button:hover {
  background-color: #4cccf7; /* Lighter pink on hover */
}

/* Active (clicked) styles */
.quest-button:active {
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.qr-button {
  width: fit-content;
  padding: 16px;
  align-self: center;
  margin-top: 15px;
}

.quest-accent {
  color: #faff00;
  font-weight: 700;
}

.quest-inactive-button {
  padding: 16px 32px;
  border: none;
  font-weight: 700;
  background: #c4c4c4;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.tab {
  padding: 10px;
  border-top: 1px solid #c4c4c4;
  font-weight: 700;
  align-items: center;
  display: flex;
  flex: auto;
  background: #d9d9d9;
  justify-content: center;
}

.selected-tab {
  background: #01bbf8 !important;
}
