.partner-text {
  color: #01BBF8;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 30px;
}

.partner-logo {
  max-width: 200px;
  transition: transform 300ms ease-out;
}

.partner-logo:hover {
  transform: scale(1.2);
}
