.bubble {
  padding: 20px 40px;
  width: 211px;
  cursor: pointer;
}

.bubble img {
  width: 211px;
  transition: transform 300ms ease-out;
}

.bubble img:hover {
  transform: scale(1.1);
}

.bubble-label {
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 35px;
  margin-top: 14px;
}

@media screen and (max-width: 1450px) {
  .bubble img:hover {
    transform: none;
  }
}

@media screen and (max-width: 760px) {
  .bubble {
    width: 125px;
    padding: 15px;
  }

  .bubble img {
    width: 125px;
  }

  .bubble img:hover {
    transform: none;
  }

  .bubble-label {
    font-size: 20px;
  }
}
