.navbar {
  position: fixed;
  justify-content: center;
  width: 100vw;
  z-index: 100;
  background: #111113;
  border-bottom: 3px solid #faff00;
}

.logo {
  align-items: center;
  padding: 8px 8px 6px 8px;
  cursor: pointer;
}

.logo img {
  max-width: 116px;
  transition: transform 0.4s ease;
}

.logo img:hover {
  transform: scale(1.1);
}

.menu {
  margin-left: 30px;
  display: flex;
}

.link {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: "Roboto";
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  padding: 0px 10px;
  transition: color 0.3s, transform 0.3s;
}

.menu-item {
  position: relative; /* Ensure proper positioning of the sub-menu */
  display: flex;
  cursor: pointer;
}

.menu-text {
  font-size: 20px;
}

.menu-item .link:hover,
.menu-item-active .link {
  background-color: #faff00;
  color: #000 !important;
}

.menu-item:hover .menu-icon {
  transform: rotate(180deg);
  filter: hue-rotate(180deg);
}

.menu-icon {
  margin-left: 10px;
  transition: transform 0.3s;
  stroke: currentColor;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #111113;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.menu-item:hover .submenu {
  display: block;
}

.dropdown-link {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: "Roboto";
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
}

.dropdown-link:hover {
  background-color: #faff00;
  color: #000;
}

.login-section {
  display: flex;
  width: 210px;
  justify-content: flex-end;
}

.login-section div {
  display: flex;
  align-items: center;
}

.login-section button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: "Roboto";
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  background: none;
}
.buy-ticket {
  display: flex;
  margin: 5px 0px 5px 22px;
}

.buy-ticket button {
  display: inline-flex;
  padding: 16px 32px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: none;
  flex-wrap: wrap;
  border-radius: 0px;
  background: #01bbf8;
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover styles */
.buy-ticket button:hover {
  background-color: #3aceff; /* Lighter pink on hover */
}

/* Active (clicked) styles */
.buy-ticket button:active {
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.burger-menu {
  display: none;
}

@media screen and (max-width: 1450px) {
  .navbar {
    justify-content: space-between;
    padding-left: 0vw;
  }

  .logo {
    padding-left: 18px;
  }

  .burger-menu {
    display: flex;
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
  }

  .menu,
  .login-section,
  .buy-ticket {
    display: none;
  }
}
