.body-text {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
}

.more-text {
  font-weight: bold;
  color: #faff00;
  font-size: 24px;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: 5px;
}

.what-description {
  margin: 30px 0px 30px 70px;
  width: 50%;
}

.maps {
  gap: 40px;
  margin-top: 40px;
  align-items: center;
}

.image-container {
  position: relative;
  height: fit-content;
}

.image-container .image {
  width: 100%;
  height: auto;
  display: block;
}

.map-link {
  transition: transform 300ms ease-out;
}

.map-link:hover {
  transform: scale(1.2);
}

.bus-stop {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  height: 36px;
  align-items: center;
}

.bus-stop img {
  transition: transform 300ms ease-out;
  z-index: 3;
}

.bus-stop img:hover {
  transform: scale(1.3);
}

.bus-stop-name {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 170px;
  height: 35px;
  background-color: white;
  padding: 2px 5px 0px 25px;
  z-index: 2;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: fit-content;
}

.bus-stop-name-show {
  display: block;
  opacity: 1;
}

.mobile {
  display: none;
}

.where {
  gap: 90px;
}

.awaits {
  margin: 0px 0px 100px 0px;
}

.tickets {
  margin: 0px 0px 0px 0px;
}

@media screen and (max-width: 1450px) {
  .mobile {
    display: flex;
    text-align: center;
  }

  .desktop {
    display: none;
  }

  .body-text {
    font-size: 20px;
  }

  .maps {
    gap: 21px;
    margin-top: 53px;
  }
}

@media screen and (max-width: 760px) {
  .what {
    flex-wrap: wrap;
  }

  .where {
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 25px;
  }

  .what-description {
    margin: 30px 0px;
    width: 100%;
  }

  .where-description {
    align-items: center;
  }

  .maps {
    gap: 58px;
  }

  .more-text {
    margin-top: 60px;
    text-align: center;
  }

  .awaits {
    margin: 100px 0px 0px 0px;
  }
}
