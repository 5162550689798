.clouds {
  position: absolute;
  bottom: -50px;
  width: 100%;
  display: block;
  z-index: 1;
}

.logo-container {
  gap: 25px;
  width: 66vw;
  padding: 3vh 4vw 0 4vw;
  justify-content: start;
}

.cyberpunk-logo {
  max-width: 70%;
}

/* Container Styling */
.logo-image-container {
  position: relative;
  display: inline-block;
  width: 705px; /* Set desired width */
  height: 87px; /* Set desired height */
  overflow: hidden;
}

/* Base Styling for All Images */
.logo-image-container .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.1s ease-in-out;
  opacity: 0; /* Hidden by default */
}

/* Show img1 by default */
.logo-image-container .logo-img1 {
  opacity: 1;
  z-index: 1;
}

/* Hover State */
.logo-image-container:hover .logo-img1 {
  opacity: 0; /* Hide img1 when hovered */
}

.logo-image-container:hover .logo-img2,
.logo-image-container:hover .logo-img3,
.logo-image-container:hover .logo-img4 {
  opacity: 1; /* Show all cycling images */
  animation: cycleImages 0.3s steps(3) infinite;
}

/* Individual Animation Delays for Sequencing */
.logo-image-container:hover .logo-img2 {
  animation-delay: 0s;
}

.logo-image-container:hover .logo-img3 {
  animation-delay: 0.1s;
}

.logo-image-container:hover .logo-img4 {
  animation-delay: 0.2s;
}

/* Keyframes for Cycling Images */
@keyframes cycleImages {
  0% {
    opacity: 1;
  }
  33.333% {
    opacity: 1;
  }
  33.334% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.logo-container .left-part {
  width: 45%;
  z-index: 1;
}

.logo-container .upper-part {
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  align-items: start;
}

.logo-container .central-part {
  width: 100%;
  height: auto;
  max-height: 83%;
  position: relative;
}

.mascot {
  max-width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  z-index: 10;
  bottom: 30px;
}

.mascot-background {
  max-height: 100%;
  max-width: 90vw;
  object-fit: contain;
  top: 0;
}

.big-logo {
  width: 360px;
}

.sub-logo {
  width: 44px;
}

.black-text {
  color: #fff;
  font-size: 35px;
  font-weight: 400;
}

.buy-ticket-button {
  padding: 32px 64px;
  border: none;
  gap: 10px;
  border-radius: 20px;
  background: #f15bb4;
  color: #fff;
  font-size: 32px;
  margin-bottom: 40px;
  margin-top: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover styles */
.buy-ticket-button:hover {
  background-color: #d64e9e; /* Lighter pink on hover */
}

/* Active (clicked) styles */
.buy-ticket-button:active {
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.image-container-bg {
  position: relative;
  height: 80%;
  z-index: 0;
}

.image-container-bg .image {
  width: 100%;
  height: auto;
  display: block;
}

.mobile-section {
  display: none;
}

.mobile-logo {
  display: none;
}

.clouds-mobile {
  display: none;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1600px) {
  .logo-container {
    width: 80vw;
  }
}

@media screen and (max-width: 1450px) {
  .mobile {
    display: none;
  }
  .logo-container {
    gap: 20px;
    padding: 4vh 0;
    width: 90vw;
    height: unset;
  }

  .big-logo {
    width: 250px;
  }

  .sub-logo {
    width: 31px;
  }

  .black-text {
    font-size: 17px;
  }

  .buy-ticket-button {
    padding: 16px 32px;
    font-size: 16px;
    border-radius: 10px;
    margin: 20px 0px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .logo-container .left-part {
    width: 55%;
  }

  .mobile-section {
    display: none;
  }

  .mobile-logo {
    display: none;
  }

  .clouds-mobile {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .cyberpunk-logo {
    max-width: 100%;
  }
  .mobile {
    display: flex;
  }
  .clouds {
    display: none;
  }

  .clouds-mobile {
    display: block;
    position: absolute;
    bottom: -5px;
    width: 100%;
  }

  .logo-container {
    display: none;
  }

  .left-part {
    display: none;
  }

  .mobile-section {
    display: flex;
    padding-top: 10px;
    width: 100%;
  }

  .desktop-section {
    display: none;
  }

  .mobile-logo {
    z-index: 1;
    padding: 30px 0px;
    padding-bottom: 50px;
    display: flex;
    flex: 0 0 auto;
    background-color: white;
    width: 100%;
  }

  .image-container-bg {
    display: none;
  }

  .image-container-bg-mobile {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
    z-index: 0;
    width: 100%;
    margin-bottom: -5px;
    margin-top: 5px;
    justify-content: center;
  }

  .image-container-bg-mobile .image {
    width: 90%; /* Make the image width 100% */
    height: auto; /* Allow the height to adjust proportionally */
    position: absolute;
    margin-top: 15px;
  }

  .buy-ticket-button {
    background: #01bbf8;
    margin: 29px 0px;
  }

  .buy-ticket-button:hover {
    background-color: #3aceff; /* Lighter pink on hover */
  }
}
