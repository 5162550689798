.contest-content {
  width: 80%;
}

@media screen and (max-width: 1450px) {
  .contest-content {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .contest-content {
    width: 100%;
    margin-top: -100px;
  }

  .contest-content h1 {
    font-size: 45px;
    line-height: 52px;
  }

  .contest-content h2 {
    font-size: 30px;
  }
}
