.login-register {
  width: 316px;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  margin-bottom: 50px;
}

.login-register button {
  border: none;
  cursor: pointer;
  background: none;
  transition: background-color 0.3s ease;
  padding: 5px 10px;
}

.login-register button:hover {
  background-color: #f0f0f0;
}

.login-register .text {
  color: black;
  font-size: 35px;
  font-family: "Roboto";
  font-weight: 500;
  word-wrap: break-word;
  text-align: center;
}

.link-text {
  color: black;
  font-size: 35px;
  font-family: "Roboto";
  font-weight: 500;
  word-wrap: break-word;
  text-decoration: none;
}

.link-text:hover {
  color: #01BBF8;
}

.logout {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  color: #000;
  font-family: "Roboto";
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  background: none;
  margin-top: 50px;
}

.userpic {
  margin: 25px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

@media screen and (max-width: 750px) {
  .userpic {
    margin: 20px;
    width: 150px;
    height: 150px;
  }
}
