.subtitle {
  color: #01BBF8;

  /* Form text */
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  line-height: 24px; /* 120% */
}

.field-label {
  font-size: 22px;
}

.field-error {
  color: #ff92d2;
}

.field {
  justify-content: space-between;
  width: 100%;
}

.field-solo {
  padding-right: 10px;
}

input,
select,
textarea {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 6px 20px;
  font-family: "Roboto";
  color: #1a1a1a;
  font-size: 20px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../../public/arrow-down.svg"); /* Replace with your custom arrow image */
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer; /* Change cursor to pointer to indicate interactivity */
}

.upload-button {
  display: flex;
  width: 390px;
  padding: 6px 10px 6px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  border: 1px solid white;
  font-family: "Roboto";
  background-image: url("../../public/upload.svg"); /* Replace with your custom arrow image */
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer; /* Change cursor to pointer to indicate interactivity */
}

.thumbnail {
  max-height: 85px;
  margin-left: 20px;
  border: 1px solid #000;
}

.audio-preview {
  align-items: flex-end;
  margin-left: 20px;
}

.apply-button {
  padding: 32px 64px !important;
  font-size: 32px !important;
}

.cancel-button {
  background-color: #d9d9d9;
  margin-left: 14px;
  color: white !important;
  margin-bottom: 0px !important;
}

.cancel-button:hover {
  background-color: #949393;
}

.form-apply {
  color: white;
  width: 70%;
  align-items: flex-start;
}

@media screen and (max-width: 1450px) {
  .field-group-dependancy {
    flex-direction: column;
  }

  .form-apply {
    width: 100%;
  }

  .upload-button {
    width: 200px;
  }

  .field-solo {
    flex-direction: column;
  }

  .img-container-thunmbnail {
    justify-content: flex-start !important;
    margin-top: 10px;
  }

  .thumbnail {
    margin-left: 0px;
  }

  .apply-button {
    padding: 16px 32px !important;
    font-size: 24px !important;
  }
}
