form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 392px;
  align-items: center;
}

form button {
  margin-top: 10px;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  cursor: pointer;
  flex-wrap: wrap;
  background: #f15bb4;
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover styles */
form button:hover {
  background-color: #d64e9e; /* Lighter pink on hover */
}

/* Active (clicked) styles */
form button:active {
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.register-link {
  color: #f15bb4;
  cursor: pointer;
}

.register-link:hover {
  color: #d64e9e;
}

.spinner-small {
  border: 3px solid #f3f3f3; /* Light gray border */
  border-top: 3px solid #f15bb4; /* Blue border on top */
  border-radius: 50%; /* Circular shape */
  width: 13px;
  height: 13px;
  animation: spin 2s linear infinite; /* Animation for spinning */
}
