.blue-bubble {
  background: url(../../public/yellow-square.svg);
  width: 225px;
  height: 225px;
  display: flex;
  align-items: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
}

.applications-countdown {
  text-align: center;
  margin: 10px 20px 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1450px) {
  .blue-bubble {
    width: 150px;
    height: 150px;
    top: -10px;
    right: -15px;
  }

  .applications-countdown {
    margin: 10px 15px 20px 15px;
  }
}

@media screen and (max-width: 760px) {
  .blue-bubble {
    width: 110px;
    height: 110px;
    top: 1px;
    right: 10px;
  }

  .applications-countdown {
    margin: 10px 10px 20px 10px;
  }
}

@media screen and (max-width: 380px) {
  .blue-bubble {
    width: 80px;
    height: 80px;
    top: 5px;
    right: 5px;
  }

  .applications-countdown {
    margin: 5px 5px 10px 5px;
    padding: 5px;
  }
}
